<template>
  <ak-container card :bodyStyle="{ padding: 0 }" class="ak-card-table">
    <PageHeader></PageHeader>
    <a-card style="width: 96%; margin: 10px auto;margin-bottom: 20px">
      <a-form-model ref="refForm" class="ak-query-filter" :model="queryParams" :colon="false">
        <a-row>
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item>
              <a-button type="primary" @click="handleAdd">新增员工</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="员工角色" prop="roleId">
              <a-select v-model="queryParams.roleId" style="min-width1: 250px;" allowClear>
                <a-select-option v-for="(item,index) in roleList" :value="item.roleId" :key="index">
                  {{item.roleName}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="员工姓名" prop="employeesName">
              <a-input v-model="queryParams.employeesName" style="min-width1: 250px;" :maxLength="20" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="员工性别" prop="sex">
              <a-select v-model="queryParams.sex" style="min-width1: 250px;" allowClear>
                <a-select-option v-for="(item,index) in dict.type.GENDER" :value="item.value" :key="index">
                  {{item.label}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="创建时间" prop="dateRange">
              <a-range-picker v-model="queryParams.dateRange" show-time class="W100" :placeholder="['请选择','请选择']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
            </a-form-model-item>
          </a-col>
          <a-col class="action-btn-main">
            <a-button type="primary" :ghost="true" @click="handleQuery">查询</a-button>
            <a-button style="margin-left: 20px" @click="handleReset">重置</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <a-table :pagination='false'
             :columns="columns"
             :data-source="list"
             :scroll="{ x: '1000px' }"
             :rowKey="(record) => record.id"
             style="width:96%; margin: 0 auto"
    >
<!--      <span slot="avatar" slot-scope="text, record">-->
<!--            <a-avatar slot="avatar" size="large" shape="square" :src="ossUrl(record.logoUrl)" style="width: 60px;height: 60px"/>-->
<!--      </span>-->
      <span slot="gender" slot-scope="text">
          <span>{{dict.label.GENDER[text]}}</span>
      </span>
      <span slot="createTime" slot-scope="text">
          <span class="table-normal">{{$util.parseTime(text, '{y}-{m}-{d} {h}:{i}')}}</span>
      </span>
      <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)" class="action">编辑</a>
          </template>
        </span>
    </a-table>
    <pagination :total="total" :pageNum.sync="queryParams.page" :pageSize.sync="queryParams.pageSize" @pagination="getList"/>

    <a-modal v-model="showAddDialog"
             width="574px"
             :bodyStyle="{'height': '593px'}"
             :keyboard="false"
             :footer="null"
             :maskClosable="false"
             :destroyOnClose="true">
      <div slot="title" class="modal-title">
        <div class="title">{{dlgMode == '1' ? '新增员工' : '编辑员工'}}</div>
      </div>
      <EmpEdit :data="dlgForm" :mode="dlgMode" @handleCancel="handleEmpAddCancel" @handleOk="handleEmpAdd"/>
    </a-modal>
  </ak-container>
</template>

<script>

  import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
  import * as EmployeeApi from '@/api/set/employee.api'
  import * as RoleApi from '@/api/set/role.api'
  import EmpEdit from './edit'

  const columns = [
    // {title: '头像', dataIndex: 'logoUrl', width: 87, align: 'center', scopedSlots: {customRender: 'avatar'}},
    {title: '员工姓名', dataIndex: 'employeesName', width: 110, align: 'center',},
    {title: '性别', dataIndex: 'sex', width: 90, align: 'center', scopedSlots: {customRender: 'gender'}},
    {title: '员工角色', dataIndex: 'roleName', width: 110, align: 'center',},
    {title: '联系方式', dataIndex: 'phone', width: 130, align: 'center',},
    {title: '登录账号', dataIndex: 'account', width: 100, align: 'center'},
    {title: '邮箱', dataIndex: 'email', width: 90, align: 'center',},
    {
      title: '创建时间', dataIndex: 'createdTime', width: 150, align: 'center',
      scopedSlots: {customRender: 'createTime'},
      sorter: (a, b) => {
        let ta = (a.createdTime && new Date(a.createdTime).getTime()) || 0
        let tb = (b.createdTime && new Date(b.createdTime).getTime()) || 0
        let ret = ta - tb
        return ret
      },
    },
    {title: '操作', dataIndex: 'action', width: 150, align: 'center', fixed: 'right', scopedSlots: {customRender: 'action'}},
  ]


  export default {
    name: 'EmployeeList',
    components: {EmpEdit},
    dictTypes: [
      {dictType: 'GENDER', dataType: Number},
      {dictType: 'EmployeeStatus', dataType: Number},
    ],
    data() {
      return {
        locale,
        loading: false,
        queryParams: {
          roleId: null,
          employeesName: null,
          sex: null,
          dateRange: [], //['2022-01-09','2022-11-03'],
          createStart: null,
          createEnd: null,
          page: 1,
          pageSize: 10,
        },
        roleList: [],
        showAddDialog: false,
        dlgMode: 1,
        dlgForm: {},

        columns: columns,
        list: [],
        total: 0,
      };
    },
    props: {
      contentType: Number
    },
    created() {
      this.getInit();
      this.getList();
    },
    methods: {
      getInit() {
        RoleApi.selectRoleList().then(data => {
          this.roleList = data?.list
        })
      },

      // 列表API获取
      getList() {
        this.queryParams.createStart = this.queryParams.dateRange[0] || null
        this.queryParams.createEnd = this.queryParams.dateRange[1] || null
        EmployeeApi.search(this.queryParams).then(data => {
          // console.log(data)
          this.list = data.list || []
          this.total = data.total || 0
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },

      /**
       * 搜索查询
       */
      handleQuery() {
        this.loading = true
        this.queryParams.page = 1;
        this.getList()
      },

      handleReset() {
        this.$refs['refForm'].resetFields()
      },

      /**
       * 新增 mode=[1(新增）|2（编辑）|3（详情）]
       */
      handleAdd() {
        this.showAddDialog = true
        this.dlgForm = {}
        this.dlgMode = '1'
      },

      // 编辑
      handleEdit(record) {
        // this.$router.push({name: 'EmployeeEdit', query: {mode: '2', id: record.id}})
        this.showAddDialog = true
        this.dlgForm = record
        this.dlgMode = '2'
      },

      // 删除
      handleDelete(record) {
        this.$modal.confirm(`删除会关联该角色的员工将取消关联，请注意！`, '是否删除该角色？', {iconType: 'exclamation-circle'}).then(() => {
          EmployeeApi.del(record.id).then(data => {
            this.$modal.msg("删除成功")
            this.getList();
          }).catch(err => {
            console.log(err);
          })
        })
      },

      handleEmpAddCancel() {
        this.showAddDialog = false
      },

      handleEmpAdd() {
        this.showAddDialog = false
        this.getList();
      },
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .ant-card-body {
    padding: 18px 18px 8px 18px;
  }

  /deep/ .ant-col-xl-8 {
    padding-left: 0 !important;
  }

  .modal-title {
    display: flex;
    .title {
      font-weight: 600;
      font-size: 14px;
    }
  }
</style>
